<template>
  <v-card>
    <v-card-text>
      <v-layout wrap>
        <v-flex xs12>
          <v-text-field
            v-model="ell.position"
            label="Cargo asociado a la longlist"
            :error-messages="errors.position"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="llFilename"
            label="Archivo de longlist"
            :autocomplete="false"
            readonly
            :error-messages="errors.uploaded_file"
            disabled
          />
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="ell.category_uuid"
            label="Categoría de longlist"
            :items="categories"
          />
        </v-flex>
        <v-flex xs12>
          <v-list>
            <div>
              <h3>
                CVs asociados a Longlist
                <v-btn
                  icon
                  @click="addCv"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h3>
              <input
                ref="cvSlot"
                type="file"
                style="display: none"
                accept="*"
                @change="onCvPicked"
              >
            </div>
            <v-list-tile
              v-for="(item, idx) in mutableCvs"
              :key="`item-${idx}`"
            >
              <v-list-tile-content>
                {{ item.filename }}
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  icon
                  @click="removeCvs(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="updateArchive"
      >
        Guardar
      </v-btn>
      <v-btn
        color="warning"
        @click="closeDialog"
      >
        Cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'EditLonglistVue',
  mixins: [JWTConfigMixin],
  props: {
    ell: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mutableCvs: this.ell.cvs,
      errors: {},
      categories: [],
    };
  },
  computed: {
    llFilename() {
      return this.ell.uploaded_file.split('/').pop();
    },
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(this.$store.state.endpoints.offers.admin.llCategories, this.jwtConfig)
        .then(resp => {
          this.categories = resp.data.map(data => ({ text: data.name, value: data.uuid }));
        });
    });
  },
  methods: {
    removeCvs(item) {
      this.mutableCvs = this.mutableCvs.filter(el => el.uuid !== item.uuid);
    },
    addCv() {
      this.$refs.cvSlot.click();
    },
    onCvPicked(e) {
      if (e.target.files[0] !== undefined) {
        this.mutableCvs.push({
          file: e.target.files[0],
          filename: e.target.files[0].name,
        });
      }
    },
    closeDialog() {
      this.$emit('close');
    },
    updateArchive() {
      const payload = new FormData();
      payload.append('position', this.ell.position);
      payload.append('category', this.ell.category_uuid);
      let idx = 0;
      this.mutableCvs.forEach(cv => {
        if (typeof cv.file === 'string') {
          payload.append(`cvs[${idx}]`, cv.uuid);
        } else {
          payload.append(`cvs[${idx}]`, cv.file);
        }
        idx += 1;
      });

      axios.patch(`${this.$store.state.endpoints.offers.admin.archive}${this.ell.uuid}/`,
        payload, this.jwtConfig)
        .then(resp => {
          this.$store.commit('adminSnackbarUp', resp.data.detail);
          this.closeDialog();
        });
    },
  },
};
</script>

<style scoped>

</style>
