<template>
  <v-container>
    <v-layout wrap>
      <v-flex
        xs12
        sm3
      >
        <v-text-field
          v-model="positionFilter"
          label="Filtrar por cargo"
        />
      </v-flex>
      <v-flex
        xs12
        sm3
        offset-sm1
      >
        <v-autocomplete
          v-model="adminFilter"
          label="Filtrar por analista"
          :items="admins"
          no-data-text="No se encontraron analistas."
        />
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs8>
        <v-btn
          color="success"
          @click="loadArchive"
        >
          <v-icon>mdi-filter</v-icon>Filtrar
        </v-btn>
        <v-btn @click="clearAndLoad">
          Limpiar
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <v-spacer />
        <v-btn
          color="primary"
          @click="uploadLLDialog"
        >
          <v-icon>mdi-plus</v-icon>
          Agregar Longlist
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="longlists"
          :pagination.sync="pagination"
          hide-actions
          class="elevation-3"
        >
          <template v-slot:headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                align="center"
                style="font-size: small; font-weight: bold"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <td align="center">
              {{ props.item.position }}
            </td>
            <td align="center">
              {{ props.item.uploaded_by_name }}
            </td>
            <td align="center">
              {{ props.item.upload_date }}
            </td>
            <td align="center">
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    outlined
                    @click="showCvsDialog(props.item.cvs)"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-file-pdf-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver CVs</span>
              </v-tooltip>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    outlined
                    :href="props.item.uploaded_file"
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Descargar Longlist</span>
              </v-tooltip>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    outlined
                    :disabled="props.item.createdby_username !== $store.state.adminName"
                    @click="editLl(props.item)"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    outlined
                    :disabled="props.item.createdby_username !== $store.state.adminName"
                    @click="prepareDelete(props.item.uuid)"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:no-results>
            No se encontraron resultados.
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout v-if="longlists.length > 0">
      <v-flex
        xs12
        mt-3
      >
        <div class="text-xs-center">
          <v-pagination
            v-model="page"
            :length="pages"
            total-visible="5"
          />
        </div>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="cvsDialog"
      :max-width="width"
      persistent
    >
      <EditLonglist
        v-if="llEdit"
        :ell="editableLl"
        @close="closeEdit()"
      />
      <v-card v-if="llUpload">
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="ll.position"
                label="Cargo asociado a la longlist"
                :error-messages="errors.position"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="llFilename"
                label="Archivo de longlist"
                :autocomplete="false"
                readonly
                :error-messages="errors.uploaded_file"
                @click="pickLlFile"
              />
              <input
                ref="llSlot"
                type="file"
                style="display: none"
                accept="*"
                @change="onLlPicked"
              >
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="llCategory"
                label="Categoría de longlist"
                :items="categories"
              />
            </v-flex>
            <v-flex xs12>
              <v-list>
                <div>
                  <h3>
                    CVs asociados a Longlist
                    <v-btn
                      icon
                      @click="addCv"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </h3>
                  <input
                    ref="cvSlot"
                    type="file"
                    style="display: none"
                    accept="*"
                    @change="onCvPicked"
                  >
                </div>
                <v-list-tile
                  v-for="(item, idx) in ll.cv_files"
                  :key="`item-${idx}`"
                >
                  <v-list-tile-content>
                    {{ cv_filenames[idx] }}
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn
                      icon
                      @click="removeFromCvs(idx)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="createLLArchive"
          >
            Guardar
          </v-btn>
          <v-btn
            color="warning"
            @click="cancelDialog"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="cvDownload">
        <v-card-title>
          <h3>
            Lista de CVs
          </h3>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-tile
              v-for="(item, idx) in dialogCvs"
              :key="idx"
            >
              <v-list-tile-content>
                {{ item.filename }}
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                  icon
                  :href="item.file"
                  target="_blank"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelDialog">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-if="shouldDelete"
      >
        <v-card-title>
          <h3>Confirmar eliminación</h3>
        </v-card-title>
        <v-card-text>
          <h4>¿Está segura/o de querer eliminar esta longlist?</h4>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="deleet(deletingArchive)"
          >
            Eliminar
          </v-btn>
          <v-btn
            color="warning"
            @click="cancelDelete"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import EditLonglist from '@/components/offers/EditLonglist.vue';

export default {
  name: 'LonglistArchive',
  components: { EditLonglist },

  mixins: [JWTConfigMixin],
  data: () => ({
    positionFilter: '',
    adminFilter: '',
    editableLl: {},
    llEdit: false,
    admins: [],
    cvsDialog: false,
    longlists: [],
    cvDownload: false,
    llUpload: false,
    width: 500,
    shouldDelete: false,
    deletingArchive: '',
    pagination: {
      descending: true,
      rowsPerPage: 10,
      page: 1,
    },
    headers: [
      { text: 'Cargo' },
      { text: 'Analista' },
      { text: 'Fecha de carga' },
      { text: 'Acciones' },
    ],
    dialogCvs: [],
    llFilename: '',
    cv_filenames: [],
    errors: {},
    ll: {
      position: '',
      cv_files: [],
      uploaded_file: null,
    },
    page: 1,
    pages: 1,
    nextPage: '',
    prevPage: '',
    llCategory: '',
    categories: [],
  }),
  computed: {
    query_params() {
      const qarr = [];
      let qstr = '';
      if (this.positionFilter !== '') {
        qarr.push(`position=${this.positionFilter}`);
      }
      if (this.adminFilter !== '') {
        qarr.push(`uploaded_by=${this.adminFilter}`);
      }
      if (qarr.length > 0) {
        qstr = '?';
      }
      return qstr + qarr.join('&');
    },
  },
  watch: {
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        const queryConstruct = `${this.query_params !== '' ? `${this.query_params}&` : '?'}page=${newVal}`;
        pageEndpoint = `${this.$store.state.endpoints.offers.admin.archive}${queryConstruct}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(this.$store.state.endpoints.offers.admin.llCategories, this.jwtConfig)
        .then(resp => {
          this.categories = resp.data.map(data => ({ text: data.name, value: data.uuid }));
        });
      axios.get(this.$store.state.endpoints.offers.admin.getAdmins, this.jwtConfig)
        .then(response => {
          this.admins = response.data.map(el => ({ text: `${el.first_name} ${el.last_name}`, value: el.user_pk }));
        });
      this.loadArchive();
    });
  },
  methods: {
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.longlists = response.data.results;
          this.$store.commit('unsetLoading');
          this.setPaginator();
        });
    },
    createLLArchive() {
      const fd = new FormData();

      fd.append('position', this.ll.position);
      fd.append('category', this.llCategory);
      fd.append('uploaded_file', this.ll.uploaded_file);
      let idx = 0;
      this.ll.cv_files.forEach(cv => {
        fd.append(`cv_files[${idx}]`, cv);
        idx += 1;
      });
      this.$store.commit('setLoading');

      axios.post(this.$store.state.endpoints.offers.admin.archive, fd, this.jwtConfig)
        .then(() => {
          this.$store.commit('unsetLoading');
          this.cvsDialog = false;
          this.llUpload = false;
          this.$store.commit('adminSnackbarUp', 'Longlist cargada con éxito.');
          this.loadArchive();
        })
        .catch(err => {
          this.errors = err.error;
        });
    },
    uploadLLDialog() {
      this.cvsDialog = true;
      this.llUpload = true;
      this.width = 500;
    },
    cancelDialog() {
      this.cvsDialog = false;
      this.llUpload = false;
      this.cvDownload = false;
    },
    showCvsDialog(cvs) {
      this.dialogCvs = cvs;
      this.cvsDialog = true;
      this.cvDownload = true;
      this.width = 500;
    },
    pickLlFile() {
      this.$refs.llSlot.click();
    },
    addCv() {
      this.$refs.cvSlot.click();
    },
    onLlPicked(e) {
      this.ll.uploaded_file = e.target.files[0] !== undefined ? e.target.files[0] : null;
      this.llFilename = e.target.files[0] !== undefined ? e.target.files[0].name : '';
    },
    onCvPicked(e) {
      if (e.target.files[0] !== undefined) {
        this.ll.cv_files.push(e.target.files[0]);
        this.cv_filenames.push(e.target.files[0].name);
      }
    },
    loadArchive() {
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.offers.admin.archive + this.query_params,
        this.jwtConfig)
        .then(response => {
          this.$store.commit('unsetLoading');
          this.longlists = response.data.results;
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.setPaginator();
        })
        .catch(() => {});
    },
    clearAndLoad() {
      this.positionFilter = '';
      this.adminFilter = '';
      this.loadArchive();
    },
    setPaginator() {
      this.pagination.page = this.page;
      this.pagination.rowsPerPage = this.longlists.length;
    },
    removeFromCvs(idx) {
      this.cv_filenames.splice(idx, 1);
      this.ll.cv_files.splice(idx, 1);
    },
    prepareDelete(archive) {
      this.cvsDialog = true;
      this.shouldDelete = true;
      this.deletingArchive = archive;
      this.width = 250;
    },
    cancelDelete() {
      this.cvsDialog = false;
      this.shouldDelete = false;
      this.deletingArchive = '';
    },
    deleet(archive) {
      this.$store.commit('setLoading');
      axios.delete(`${this.$store.state.endpoints.offers.admin.archive}${archive}/`, this.jwtConfig)
        .then(() => {
          this.cancelDelete();
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Longlist eliminada con éxito.');
          this.loadArchive();
        })
        .catch(e => {
          this.$store.commit('adminSnackbarUp', e.response.detail);
        });
    },
    editLl(ll) {
      this.editableLl = ll;
      this.cvsDialog = true;
      this.llEdit = true;
      this.width = 500;
    },
    closeEdit() {
      this.cvsDialog = false;
      this.llEdit = false;
      this.editableLl = {};
      this.loadArchive();
    },
  },
};
</script>
